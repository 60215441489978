<template>
  <v-data-table
    :headers="headers"
    :items="data"
    disable-sort
    class="elevation-1"
    :loading="loadTable"
    loading-text="Loading... Please wait"
  >
    <template v-slot:[`item.eDate`]="{ item }">
      <v-icon
        medium
        :color="getColor(item.eDate)"
        v-text="getIcon(item.eDate)"
      />
    </template>
    <template v-slot:[`item.sma50`]="{ item }">
      <v-icon
        medium
        :color="getColor(item.sma50)"
        v-text="getIcon(item.sma50)"
      />
    </template>
    <template v-slot:[`item.active`]="{ item }">
      <v-icon
        medium
        :color="getColor(item.active)"
        v-text="getIcon(item.active)"
      />
    </template>

    <template v-slot:top>

      <!-- Remind Passowrd -->
      <v-alert
        dismissible
        :type="tipo"
        v-if="msg"
        elevation="3"
      >
        {{ msg }}
      </v-alert>

      <v-toolbar
        flat
      >
        <v-toolbar-title>Operaciones Programadas</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="800px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              Nueva Operacion
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="3"
                  >
                    <v-text-field
                      v-model="editedItem.name"
                      label="Nombre"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="3"
                  >
                    <v-select
                      v-model="editedItem.type"
                      :items="typeItems"
                      label="Tipo"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="3"
                  >
                    <v-menu
                      v-model="menuExpiredDate"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem.expiredAt"
                          label="Fecha Expiracion"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="editedItem.expiredAt"
                        :min="minExpiredDate"
                        @input="menuExpiredDate = false"
                      />
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="3"
                  >
                    <v-text-field
                      v-model="editedItem.dem"
                      label="D.E.M"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="3"
                  >
                    <v-select
                      v-model="editedItem.des"
                      :items="desItems"
                      label="D.E.S"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="3"
                  >
                    <v-text-field
                      v-model="editedItem.gn"
                      label="G.N"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="3"
                  >
                    <v-select
                      v-model="editedItem.openInterest"
                      :items="openInterestItems"
                      label="Open Interest"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="3"
                  >
                      <v-switch
                        v-model="editedItem.eDate"
                        label="Earning Date"
                      ></v-switch>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="3"
                  >
                      <v-switch
                        v-model="editedItem.sma50"
                        label="SMA50"
                      ></v-switch>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="3"
                  >
                  <v-menu
                      v-model="menuFinishDate"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem.finishDate"
                          label="Fecha Finalizacion"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="editedItem.finishDate"
                        :min="minExpiredDate"
                        @input="menuFinishDate = false"
                      />
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="3"
                  >
                      <v-switch
                        v-model="editedItem.active"
                        label="Activo"
                      ></v-switch>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog> -->
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <!-- <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon> -->
    </template>
  </v-data-table>
</template>
<script>
  import axios from 'axios'
  const RUTA_SERVIDOR = 'https://www.financetradebio.com'
  export default {
    data: () => ({
      dialog: false,
      // dialogDelete: false,
      headers: [
        {
          text: 'Nombre',
          align: 'start',
          value: 'name',
        },
        { text: 'Tipo', value: 'type' },
        { text: 'Fecha Expiracion', value: 'expiredAt' },
        { text: 'D.E.M', value: 'dem' },
        { text: 'D.E.S', value: 'des' },
        { text: 'G.N', value: 'gn' },
        { text: 'Open Interest', value: 'openInterest' },
        { text: 'Earning Date', value: 'eDate' },
        { text: 'SMA50', value: 'sma50' },
        { text: 'Fecha Finalizacion', value: 'finishDate' },
        { text: 'Activo', value: 'active' },
        { text: 'Editar', value: 'actions' },
      ],
      menuExpiredDate: false,
      menuFinishDate: false,
      tipo: '',
      error: false,
      msg: '',
      editedIndex: -1,
      loadTable: true,
      loading: false,
      data: [],
      typeItems: [
        {
          value: 'call',
          text: 'Call',
        },
        {
          value: 'put',
          text: 'Put',
        },
      ],
      desItems: [
        {
          value: '5.00',
          text: '5.00',
        },
        {
          value: '10.00',
          text: '10.00',
        },
        {
          value: '20.00',
          text: '20.00',
        },
      ],
      openInterestItems: [
        {
          value: '50.00',
          text: '50.00',
        },
        {
          value: '100.00',
          text: '100.00',
        },
        {
          value: '200.00',
          text: '200.00',
        },
      ],
      editedItem: {
        id: null,
        name: null,
        type: null,
        expiredAt: '',
        dem: 0,
        des: null,
        gn: 0,
        openInterest: null,
        eDate: false,
        sma50: false,
        finishDate: '',
        active: false,
      },
      defaultItem: {
        name: null,
        type: null,
        expiredAt: '',
        dem: 0,
        des: null,
        gn: 0,
        openInterest: null,
        eDate: false,
        sma50: false,
        finishDate: '',
        active: false,
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nueva Operacion' : `Editando ${this.editedItem.name}`
      },
      minExpiredDate() {
        const now = new Date()
        const day = now.getDate() < 10 ? `0${now.getDate()}` : now.getDate()
        const month =
          now.getMonth() < 10 ? `0${now.getMonth() + 1}` : now.getMonth() + 1
        return `${now.getFullYear()}-${month}-${day}`
      },
      resultData() {
        return this.data
      },
    },

    async mounted() {
      const data = await this.getData()
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      // dialogDelete (val) {
      //   val || this.closeDelete()
      // },
    },

    created() {
      const token = localStorage.getItem('token')
      if (!token) {
        window.location.href = '/login'
      }
    },

    methods: {
      async getData() {
        this.loading = true
        const data = await axios.get(`${RUTA_SERVIDOR}/scheduledoperation`).then(res => res.data.data)
        this.data = data
        this.loadTable = false
        this.loading = false
      },

      editItem (item) {
        this.editedIndex = this.data.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      // deleteItem (item) {
      //   this.editedIndex = this.data.indexOf(item)
      //   this.editedItem = Object.assign({}, item)
      //   this.dialogDelete = true
      // },

      // deleteItemConfirm () {
      //   this.data.splice(this.editedIndex, 1)
      //   this.closeDelete()
      // },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      // closeDelete () {
      //   this.dialogDelete = false
      //   this.$nextTick(() => {
      //     this.editedItem = Object.assign({}, this.defaultItem)
      //     this.editedIndex = -1
      //   })
      // },

      async save () {
        this.tipo = ''
        this.msg = ''
        const json = {
          name: this.editedItem.name,
          type: this.editedItem.type,
          expiredAt: this.editedItem.expiredAt,
          dem: parseFloat(this.editedItem.dem),
          des: parseFloat(this.editedItem.des),
          gn: parseFloat(this.editedItem.gn),
          openInterest: parseFloat(this.editedItem.openInterest),
          eDate: this.editedItem.eDate,
          sma50: this.editedItem.sma50,
          finishDate: this.editedItem.finishDate,
          active: this.editedItem.active,
        }
        const config = {
            headers: { Authorization: `bearer ${localStorage.getItem('token')}` }
        }
        if (this.editedIndex > -1) {
         await axios
            .put(`${RUTA_SERVIDOR}/scheduledOperation/${this.editedItem.id}`, json, config)
            .then(({ data: res }) => {
              this.tipo = 'success'
              this.msg = res.message
              Object.assign(this.data[this.editedIndex], this.editedItem)
            })
            .catch((error) => {
              if (error.response.data.statusCode === 401 || error.response.data.statusCode === 400) {
                this.tipo = 'error'
                this.msg = error.response.data.message
              }
            })
        } else {
         await axios
            .post(`${RUTA_SERVIDOR}/scheduledOperation`, json, config)
            .then(({ data: res }) => {
              this.tipo = 'success'
              this.msg = res.message
              this.data.push(this.editedItem)
            })
            .catch((error) => {
              if (error.response.data.statusCode === 401 || error.response.data.statusCode === 400) {
                this.tipo = 'error'
                this.msg = error.response.data.message
              }
            })
        }
        this.close()
      },

      getColor (status) {
        if (status) return 'green'
        else return 'red'
      },

      getIcon (icon) {
        if (icon) return 'mdi-check-circle-outline'
        else return 'mdi-close-circle-outline'
      },
    },
  }
</script>
<style lang="scss">
  .modal {
    display: flex;
    justify-content: center;
    margin: 10px 0;
    align-items: center;
    span.tt {
      margin-right: 25px;
    }
  }
  .companies-error {
    ul {
      display: flex;
      list-style: none;
      flex-wrap: wrap;
      padding: 0;
      padding-top: 10px;
      li {
        padding-right: 10px;
        font-size: 18px;
        color: #000;
        font-weight: normal;
      }
    }
  }
  .range-field {
    flex: none;
    padding: 0;
    .v-text-field__details {
      display: none !important;
    }
  }
  .gn td {
    color: red !important;
  }
  .strike-column {
    background: #eaebec;
  }
  .v-data-footer__select .v-input {
    display: none !important;
  }
</style>
